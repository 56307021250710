import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import emailverificationImage from '../../assets/emailverification-image.svg'
import profileimageBg from '../../assets/profileimage-bg.svg'
import { useIsMobile } from '../../hooks/useIsMobile'
import { resendVerificationEmail } from '../../redux/actions/AuthActions'
import ProfileImage from '../../routes/Profile/components/profile/ProfileImage'
import { ModalContainer } from '../common/ModalContainers'
import {
  ConfirmButton,
  ResendButton,
  VerfiyEmailAvatarContainer,
  VerfiyEmailContainer,
  VerifyEmailAddress,
  VerifyEmailAvatarOverlay,
  VerifyEmailSubtitle,
  VerifyEmailTitle,
  EmailImage
} from './Auth.styles'
import { useIsTutorial } from '../../hooks/useIsTutorial'

const VerifyEmailSimpleModal = ({ onClose, reminder }) => {
  const isMobile = useIsMobile()
  const { me } = useSelector(state => state.profile)
  const isTutorial = useIsTutorial()

  const wrapperRef = useRef(null)

  const sendVerificationEmail = useCallback(() => {
    resendVerificationEmail(me.email).then(() =>
      alert('Email Successfully Resent')
    )
  }, [me?.email])

  if (isTutorial) return null

  return (
    <ModalContainer
      centered
      fullscreen
      onClose={onClose}
      wrapperRef={wrapperRef}
    >
      <VerfiyEmailContainer>
        {reminder ? (
          <EmailImage src={emailverificationImage} alt="" />
        ) : (
          <VerfiyEmailAvatarContainer>
            <ProfileImage
              borderRadius={6}
              user={me}
              showLevel={false}
              size={isMobile ? 128 : 160}
            />
            <VerifyEmailAvatarOverlay src={profileimageBg} alt="" />
          </VerfiyEmailAvatarContainer>
        )}
        {!reminder && (
          <VerifyEmailTitle>
            Hi{' '}
            {me && me.uniqueDisplayName
              ? ' ' + me.uniqueDisplayName
              : ' ' + me.username}
            ,
            <br />
            Welcome to DecorMatters
          </VerifyEmailTitle>
        )}
        <VerifyEmailSubtitle>
          {reminder
            ? 'To access these features, please verify your email at:'
            : 'A confirmation email is sent to'}
          <br />
          <VerifyEmailAddress>{me?.email || 'your email'}</VerifyEmailAddress>
        </VerifyEmailSubtitle>
        <ConfirmButton onClick={onClose}>Ok</ConfirmButton>
        <ResendButton onClick={sendVerificationEmail}>
          Didn’t recieve email? <span>Resend</span>
        </ResendButton>
      </VerfiyEmailContainer>
    </ModalContainer>
  )
}

export default VerifyEmailSimpleModal
