import React from 'react'
import closeImg from '../../assets/close.svg'
import * as styles from './ModalOverlay/mc.module.css'

const CloseButton = ({ onClose }) => {
  return (
    <button className={styles.clb} onClick={onClose}>
      <img src={closeImg} alt="" className={styles.cli} />
    </button>
  )
}

export default CloseButton
