import classNames from 'classnames'
import React from 'react'
import CloseButton from './CloseButton'
import ModalOverlay from './ModalOverlay'
import * as styles from './ModalOverlay/mc.module.css'

export const AuthModalContainer = ({
  wrapperRef,
  onClose,
  title,
  description,
  children
}) => {
  return (
    <ModalOverlay>
      <div ref={wrapperRef}>
        <div className={styles.lo}>
          <div className={styles.inw}>
            <div className={styles.cl}>
              <CloseButton onClose={onClose} />
            </div>
            <div className={styles.indd}>
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
          </div>
          {children}
        </div>
      </div>
    </ModalOverlay>
  )
}

export const ModalContainer = ({
  wrapperRef,
  onClose,
  hideBg,
  centered,
  fullscreen,
  children
}) => {
  return (
    <ModalOverlay
      centered={centered}
      fullscreen={fullscreen}
      wrapperRef={wrapperRef}
    >
      <div
        className={classNames(styles.lom, {
          [styles.hideBg]: !hideBg
        })}
      >
        <div className={styles.clm}>
          <CloseButton onClose={onClose} />
        </div>
        <div>{children}</div>
      </div>
    </ModalOverlay>
  )
}
