import { useSelector } from 'react-redux'
import { skipOnboarding } from '../utils/authLocalStorage'

export const useIsTutorial = () => {
  const profile = useSelector(state => state?.profile?.me)
  const welcomeRecord = useSelector(state => state.checkIn?.welcomeRecord)
  const tutorialSeen =
    useSelector(state => state?.profile?.me?.tutorialSeen) || false

  const isTutorial =
    !tutorialSeen &&
    !(welcomeRecord?.rewardedWgIds || []).includes('wBQjZVuZxn') &&
    typeof window !== 'undefined' &&
    localStorage.getItem(skipOnboarding) !== 'true'

  const hasSignsOfActivity =
    profile?.numCoins > 100 || profile?.numDesigns > 0 || profile?.userLevel > 1

  return isTutorial && !hasSignsOfActivity
}
