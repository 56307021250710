import styled from 'styled-components'
import profileBG from '../../assets/profile-bg.svg'

export const FormContainer = styled.div`
  flex: auto;
  background-color: white;
  box-shadow: none;
  padding: 0 40px 0 40px;
  margin: 0;
  height: auto;
  position: relative;

  @media (max-width: 820px) and (min-width: 600px) {
    position: absolute;
    top: 10%;
    box-sizing: border-box;
    left: 10vw;
    width: 80vw;
  }

  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh;
  }

  @media only screen and (min-width: 768px) {
    flex: 0 0 500px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    padding: 80px 80px 40px 80px;
    margin: 0 20px;
    height: auto;
  }

  @media only screen and (max-height: 786px) {
    margin-bottom: 50px;
    margin-top: 20%;
  }

  @media only screen and (max-width: 900px) {
    margin: 0 0;
  }
`

export const FormLogo = styled.img`
  height: 50px;
  width: 150px;
  padding-top: 60px;

  @media only screen and (max-width: 600px) {
    padding-top: 0px;
    position: absolute;
    top: 21px;
    left: 20px;
    height: 30px;
  }

  @media only screen and (min-width: 768px) {
    padding-top: 0;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }

  @media only screen and (max-height: 786px) {
    padding-top: 0;
    top: 30px;
  }
`

export const FormFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  font-weight: 500;
  font-size: 15px;

  @media only screen and (max-height: 786px) {
    position: initial;
    font-weight: 500;
    font-size: 15px;
    padding: 0 10px;
    padding-bottom: 100px;
  }
`

export const FooterList = styled.ul`
  text-align: center;
  padding: 0;
`

export const FooterItem = styled.li`
  display: inline;
  padding: 0 4px;
  color: #8b8b8b;
  font-size: 12px;
`

export const FooterLink = styled.a`
  color: #333333;
  text-decoration: none;
  font-size: 12px;
`

export const BGCredentials = styled.div`
  position: absolute;
  left: 10px;
  bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  color: white;

  @media only screen and (max-height: 786px) {
    display: none;
  }
`

export const BGCredentialsLink = styled.a`
  color: white;
`

//

export const TextCenter = styled.span`
  text-align: center;
  position: relative;

  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
    font-size: 14px;
  }
`

export const Heading = styled.h1`
  padding-bottom: 25px;
  text-align: center;
`

export const GreetingHeading = styled(Heading)`
  width: '100%';
  overflow: 'hidden';
  textoverflow: 'ellipsis';
  fontsize: ${({ isMobile }) => (isMobile ? '20px' : '24px')};
`

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-self: stretch;
`

export const LargeButtonContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`

export const ImageContainer = styled.div`
  display: inline-block;
  padding: 10px;
  border: 8px solid #e5e5e5;
  border-radius: 50%;
`

export const Picture = styled.img`
  width: 166px;
  height: 166px;
  border-radius: 80px;
  object-fit: contain;
`

export const PictureBackground = styled.div`
  position: absolute;
  top: 0;
  background-image: url(${profileBG});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 3;
`

export const UsernameUpdateButton = styled(LargeButtonContainer)`
  margin: 0;
  padding: 0;
`

export const LoginHeading = styled.div`
  padding-bottom: 20px;

  & h1 {
    margin-top: 0;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 0;
    padding-top: 100px;
    text-align: center;

    & h1 {
      font-size: 26px;
    }

    & p {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
    }
  }
`

export const AccountContainer = styled.div`
  text-align: center;
  margin-top: 16px;

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  & button {
    text-decoration: underline;
    cursor: pointer;
    border: none;
    padding: 0;
    outline: none;
    background: transparent;

    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const FormSpacing = styled.div`
  margin-top: 30px;
`

export const VerifyEmailBackground = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export const VerfiyEmailContainer = styled.div`
  width: 624px;
  height: 662px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 700px) {
    height: 550px;
  }
`

export const VerfiyEmailAvatarContainer = styled.div`
  position: relative;
  width: 260px;
  height: 179px;
  margin-top: 117px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmailImage = styled.img`
  margin-top: 117px;
  margin-bottom: 40px;
  width: 272px;
  height: 223px;
`

export const VerifyEmailAvatarOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`

export const VerifyEmailTitle = styled.h2`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #000000;
  margin-top: 64px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 36px;
  }
`

export const VerifyEmailSubtitle = styled.p`
  color: #000000;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0;
`

export const VerifyEmailAddress = styled.span`
  display: block;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #000;
`

export const ConfirmButton = styled.button`
  width: 249px;
  height: 48px;
  background: #ff5e6d;
  border-radius: 45px;

  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;

  color: #ffffff;
  text-align: center;
  border: none;
  margin-top: 40px;
  cursor: pointer;
`

export const ResendButton = styled.button`
  text-align: center;
  color: #000;
  border: none;
  background: transparent;
  cursor: pointer;

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;

  margin: auto 0 28px;

  & > span {
    color: #ff5e6d;
    font-weight: 700;
    text-decoration: underline;
  }
`
